// WaGen___HASHKEY__5144b2d2_2024-01-16 11:13:58 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
未提供注释
*/
export function dzfpService_getPjdyxxByNfHbbhZwyf(hbbh,zwyf,jgbm, meta) {
	return fetch({
		url : 'psdmszhcx/service/DzfpService/getPjdyxxByNfHbbhZwyf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				hbbh : hbbh, //Integer
				zwyf : zwyf, //Integer
				jgbm : jgbm //String
			}
		}
	})
}

