<template>
  <page-view title="合打金额明细" left-arrow :nav-border="false" custom-class="dfzwPage">
    <div :class="['dzfp-item','invoice-detail-view']" v-if="hdjeRecord.length">
      <van-cell-group class="dzfp-item--content">
        <van-cell :border="false" title="统一结算号：">{{headInfo.hbbh}}</van-cell>
        <van-cell :border="false" title="用户名称：">{{headInfo.yhmc}}</van-cell>
        <van-cell :border="false" title="总户数:">{{hdjeRecord.length}}</van-cell>
        <div style="padding: 5px 16px;font-weight: 900;font-size: 14px;">电网维护费、电费发票类型电量、电费统计:</div>
        <van-cell :border="false" title="总电量：">{{headInfo.zdl}}</van-cell>
        <van-cell :border="false" title="总票据金额：">{{headInfo.zysdf | currency}}</van-cell>
      </van-cell-group>
    </div>
    <div :class="['dzfp-item','invoice-detail-view']" v-for="(child,idx) in hdjeRecord" :key="idx">
      <van-cell-group class="dzfp-item--content">
        <van-row>
          <van-col :span="12">
            <van-cell :border="false" title="户号:" style="padding: 0 0 0 16px">{{child.yhbh}}</van-cell>
          </van-col>
          <van-col :span="12">
            <van-cell :border="false" title="开票标志：">
              <van-tag :type="child.dybz == 1?'success':'default'">{{child.dybz == 1?'已开票':'未开票'}}</van-tag>
            </van-cell>
          </van-col>
        </van-row>
        <van-row>
          <van-col :span="12">
            <van-cell :border="false" title="票据号码：" style="padding: 0 0 0 16px">{{child.pjhm?child.pjhm:'无'}}</van-cell>
          </van-col>
          <van-col :span="12">
            <van-cell :border="false" title="电量：">{{child.srxmsl}}</van-cell>
          </van-col>
        </van-row>
        <van-cell :border="false" title="数据类型：">{{child.pjsjlxmc}}</van-cell>
        <van-cell :border="false" title="票据金额：">{{child.ysdf | currency}}</van-cell>
      </van-cell-group>
    </div>
  </page-view>
</template>

<script>
import {dzfpService_getPjdyxxByNfHbbhZwyf} from "@/api/psdmszhcx/service/DzfpServiceAPI";

export default {
  name: "hdje-record",
  data() {
    return {
      hdjeRecord:[],
      headInfo:{
        zdl:'',
        zysdf:'',
        hbbh:'',
        yhmc:'',
      },
    }
  },
  async created() {
    let loading = this.$toast.loading({
      message: "加载中...",
      duration: 0
    })
    let {tyjsh,zwyf,jgbm} = this.$route.query;
    let hdjeRes = await dzfpService_getPjdyxxByNfHbbhZwyf(tyjsh,zwyf,jgbm);
    loading.clear();
    if(hdjeRes.code==200 && hdjeRes.content.status==200){
      let zdl = 0;
      let zysdf = 0;
      let yhmc = '';
      hdjeRes.content.data.forEach((item,index)=>{
        if(item.pjsjlx==1 || item.pjsjlx==4){
          zdl += item.srxmsl;
          zysdf += item.ysdf;
        }
        yhmc = item.yhmc;[]
      })
      this.hdjeRecord = hdjeRes.content.data;
      this.headInfo = {
        zdl:zdl,
        zysdf:zysdf,
        hbbh:tyjsh,
        yhmc:yhmc,
      };
    }else{
      this.$toast.fail(hdjeRes.content && hdjeRes.content.message?hdjeRes.content.message:hdjeRes.message);
    }
  }
}
</script>

<style scoped lang="scss">
$top-bg-color: #00b1ff;

.dfzwPage /deep/ {
  .van-nav-bar {
    background-color: $top-bg-color;
    color: #fff;
  }
  .van-nav-bar .van-icon,
  .van-nav-bar__title {
    color: #fff;
  }
}

.dzfp-item /deep/ {
  background-color: #fff;
  margin: 10px;

  .dzfp-item--header {
    padding: 10px 16px;
  }

  .zwyf {
    background-color: #8c8c8c;
    font-size: 14px;
    color: #fff;
    margin-right: 5px;
  }

  .dzfp-item--content {
    padding: 10px 0;
    .van-cell {
      padding: 0 16px;
    }
  }

  .dzfp-item--actions {
    margin: 0 16px;
    padding: 10px 0;
    text-align: right;

    .van-button {
      margin-right: 10px;
      padding: 0 16px;
      border-radius: 3px;
      height: 28px;
      &:last-child {
        margin-right: 0;
      }
    }

    .divider {
      width: 1px;
      height: 16px;
      background-color: #ddd;
    }
  }
}
</style>